import React from "react"
import PropTypes from "prop-types"

class ControllerGpioStatus extends React.Component {
    constructor(props) {
        super(props);
        this.refreshTimerStarted = false
        this.lastRefreshCompleteTime = null
        this.state = {
          requestedGpioStates: {controller_id: this.props.controller_id, outputs:{}, inputs:{}},
          actualGpioStates: {},
          isLoading: false,
          errorMessage: null,
        };
        this.handleGpioStateChange = this.handleGpioStateChange.bind(this);
    }

    async onNotesChange(gpioNum, notes) {
      console.log("onNotesChange called for gpio number "+gpioNum+" with notes "+JSON.stringify(notes));
      var requestedGpioStates = this.state.requestedGpioStates;
      console.log("state is "+JSON.stringify(this.state));
      console.log("Current requested gpio state is "+JSON.stringify(requestedGpioStates));
      requestedGpioStates.outputs[""+gpioNum].notes = notes;
      this.setState({requestedGpioStates: requestedGpioStates});
    }
      
    async handleGpioStateChange(gpioNum, state, notes) {
        console.log("handle on called for gpio number "+gpioNum)
        var that = this;
        const requestedState = this.state.requestedGpioStates.outputs[gpioNum];
        if (requestedState) {
          if (state != null) {
            console.log("Changing gpio state to "+state);
            requestedState.state = state ? 1: 0;
          }
          if (notes != null) {
            console.log("Changing gpio notes to "+notes);
            requestedState.notes = notes;
          }
        }  
        const requestedGpioStates = this.state.requestedGpioStates;
        requestedGpioStates.outputs[gpioNum] = requestedState;
        requestedGpioStates["requestedChangeChangeCount"] = this.state.actualGpioStates.changeCount || -1;
        requestedGpioStates["controller_id"] = this.getControllerId();
        
        that.setState({isLoading: true, errorMessage: null, requestedGpioStates: requestedGpioStates});
        var url = this.props.baseUrl+'/api/v2/gpio_states';
        var responseAfterPromise = null;
        fetch(url, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestedGpioStates)
          })
          .then(res => {responseAfterPromise = res; return res.json();})
            .then(json => {
              if (responseAfterPromise.status === 200) {
                console.log("Got server response after processing user input "+json);
                var requestedGpioStates = this.state.requestedGpioStates;
                that.setState({actualGpioStates: json, requestedGpioStates: requestedGpioStates, isLoading: false, errorMessage: null});
                that.scheduleRefreshIfNeeded()
                return Promise.resolve(json)
              }
              var errorMessage = responseAfterPromise.statusText;
              if (json.error) {
                errorMessage = json.error;
              }
              return Promise.reject(new Error(errorMessage))
            })
            .catch(function(error) {
              that.setState({isLoading: false});
              var errorString = ""+error;
            that.setState({isLoading: false, errorMessage: ''+error});
          });    
    }
    
    
    isDirty(gpioNum) {
        var requestedState = this.state.requestedGpioStates.outputs[""+gpioNum];
        var actualState = this.state.actualGpioStates.outputs[""+gpioNum];
        if (requestedState && actualState) {
          if (requestedState.state !=  actualState.state) {
            console.log("dirty because requested state of "+requestedState.state+" !=  "+actualState.state);
            return true;
          }
          else {
            console.log("not dirty because all values match  "+requestedState.state+" ==  "+actualState.state);
          }
        }
        else {
          console.log("dirty because something is unknown");
          return true;
        }
        return false;
    }
    isAnythingDirty() {
      var gpioNum = 1;
      while (this.state.actualGpioStates.outputs[""+gpioNum]) {
        if (this.isDirty(gpioNum)) {
          return true;
        }
        gpioNum += 1;
      }
      return false;
    }
      
    scheduleRefreshIfNeeded() {
        if (this.isAnythingDirty) {
            if (this.lastRefreshCompleteTime == null || (new Date().getTime() - lastRefreshCompleteTime.getTime() > 5.0)) {
                if (!this.refreshTimerStarted) {
                    this.refreshTimerStarted = true
                    console.log("We will refresh in 5 seconds...")        
                    setTimeout(function() {         
                      this.refreshTimerStarted = false
                      console.log("We are refreshing now")
                      this.fetchGpioStates()          
                    }.bind(this), 5000)    
                }
                else {
                    console.log("Refresh timer already started");
                } 
            }
        }
    }
    
    render () {
        console.log("Rendering");
        const that = this;
        var header = "GPIO Status Loading...";
        if (this.state.errorMessage) {
          header = "Error Loding Data: "+this.state.errorMessage;
        }
        else if (this.state.requestedGpioStates && this.state.requestedGpioStates.outputs["1"]) {                    
          header = ""
        }
        var connecting = false
    
        var main_table_rows = [];
        var gpio_number = 1;
        const requestedOutputGpioStates = this.state.requestedGpioStates.outputs;
        console.log("Requested gpio states are "+JSON.stringify(requestedOutputGpioStates));
        console.log("we start looking for "+requestedOutputGpioStates[""+gpio_number]);
        while (requestedOutputGpioStates[""+gpio_number]) {
          console.log("Processing gpio number "+gpio_number);
          const key = ""+gpio_number;
          const gpioState = requestedOutputGpioStates[key]
          if (!gpioState) {
            console.log("No gpio state for key "+key);
            console.log(JSON.stringify(requestedOutputGpioStates));
          }
          var status = gpioState.state || 0;
          var dirty = this.isDirty(key)
          var spinner = ""
          var notes =   (
            <span>
            <input type="text" 
               disabled={false}
               value={requestedOutputGpioStates[key].notes}
               onChange={e => {that.onNotesChange(key, e.target.value)}} />&nbsp;
            <input type="button" onClick={this.handleGpioStateChange.bind(this,gpio_number,null, requestedOutputGpioStates[key].notes) } value="Save Notes"/>
            </span>
          )
          
          
          
          this.state.actualGpioStates.outputs[key].notes;
          var checkbox = (
            <input type="checkbox" onChange={this.handleGpioStateChange.bind(this,gpio_number,!status, null)} checked={status}/>
          )

              
          if (dirty) {  
            console.log("Showing spinner");      
            spinner = ( <img className="spinner" src="/assets/spinner.gif"/> )
          }
          else {
            console.log("Hiding spinner");      
          }
          var row = (
                <tr key={key}>
                <td>{key}</td>
                <td>{checkbox}&nbsp;{spinner}</td>
                <td>{notes}</td>
                </tr>
                );
          main_table_rows.push(row)
          gpio_number += 1;
       }
    
        if (main_table_rows.length > 0) {
        return (
            <React.Fragment>
            <div id="controller_tunnel" className={this.state.isLoading ? "loading" : "done-loading"}>
            {header}
            <table className="main_table">
            <thead>
            <tr>
            <th>GPIO Output #</th>
            <th>Control</th>
            <th>Notes</th>
            <th></th>
            </tr>
            </thead>
            <tbody>
            {main_table_rows}
            </tbody>
            </table>
            </div>
            </React.Fragment>
          );
        }
        else {
        return (
            <React.Fragment>
            <div id="tag_reads" className={this.state.isLoading ? "loading" : "done-loading"}>
            {header}
            </div>
            </React.Fragment>
          );
        }
    }
    
    componentDidMount() {
        this.setState({isLoading: true, errorMessage: null});
        this.fetchGpioStates();
    }
    
    getControllerId() {
      console.log("controller id is "+this.props.controller_id);
      return  this.props.controller_id; 
    }
    
    fetchGpioStates() {    
        var that = this;
        var url = this.props.baseUrl+'/api/v2/gpio_states';
        var responseAfterPromise = null;
        fetch(url, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({controller_id: this.getControllerId()})
        })
          .then(res => {responseAfterPromise = res.clone(); return res.json();})
            .then(json => {
              if (responseAfterPromise.status === 200) {
                var requestedGpioStates = this.state.requestedGpioStates;
                var shouldReplaceRequest = false;
                if (!requestedGpioStates.requestedChangeChangeCount) {
                  console.log("no change requested.  overwriting request.");
                  shouldReplaceRequest = true;
                } 
                else if (requestedGpioStates.requestedChangeChangeCount && json.changeCount && requestedGpioStates.requestedChangeChangeCount < json.changeCount) {
                  console.log("changeCount has advanced to beyond our last requested change "+json.changeCount+" from value at time of change: "+requestedGpioStates.requestedChangeChangeCount);
                  shouldReplaceRequest = true;
                }
                else {
                  console.log("change count not satisfied.  not overwriting request (count at change request, count at update): "+requestedGpioStates.requestedChangeChangeCount+", "+json.changeCount);
                }
                if (shouldReplaceRequest) {
                  requestedGpioStates = json;   
                  requestedGpioStates["controller_id"] = this.getControllerId();                  
                }
                console.log("After update requestedGpioStates is "+JSON.stringify(requestedGpioStates));
                console.log("After update actualGpioStates is "+JSON.stringify(json));
                if (json) {
                  that.setState({actualGpioStates: json, requestedGpioStates: requestedGpioStates, isLoading: false, errorMessage: null});
                }
                that.scheduleRefreshIfNeeded()
                return Promise.resolve(json)
              }
              var errorMessage = responseAfterPromise.statusText;
              if (json.error) {
                errorMessage = json.error;
              }
              return Promise.reject(new Error(errorMessage))
            })
            .catch(function(error) {
              responseAfterPromise.text().then((txt) => console.log(`Status code: ${responseAfterPromise.status} status message: ${responseAfterPromise.statusText} Body: ${txt}`));
              var errorString = ""+error;
              console.log(error)	      
            that.setState({controller_tunnels: [], isLoading: false, errorMessage: ''+error});
        });
    }
}

ControllerGpioStatus.propTypes = {
  greeting: PropTypes.string
};
export default ControllerGpioStatus
